import { FormattedMessage } from 'react-intl';
import './ShopItem.scss';
import BuyDialog from '../BuyDialog/BuyDialog';
import { useState } from 'react';

interface Props {
   title: string;
   text: string;
   image: string;
   imagePadding: boolean;
   dark: boolean;
   helpFile: string;
   packageFile: string;
   price: number;
}

function ShopItem({
   title,
   text,
   image,
   imagePadding,
   dark,
   helpFile,
   packageFile,
   price,
}: Props) {
   const imagePath = require(`../../assets/img/${image}.webp`);
   const documentation = require(`../../assets/documentations/${helpFile}`);
   const driver = require(`../../assets/drivers/${packageFile}`);

   const [dialogIsOpen, setDialogIsOpen] = useState(false);

   const openDialog = () => {
      setDialogIsOpen(true);
   };

   const closeDialog = () => {
      setDialogIsOpen(false);
   };

   return (
      <>
         <div className={`article ${dark ? 'article--dark' : ''}`}>
            <div
               className={`article__description ${
                  dark ? 'article__description--dark' : ''
               }`}>
               <div className="article__content">
                  <h2 className="article__title">
                     <FormattedMessage id={title}></FormattedMessage>
                  </h2>
                  <p className="article__text">
                     <FormattedMessage id={text}></FormattedMessage>
                  </p>
               </div>
               <div
                  className={`article__illustration ${
                     dark ? 'article__illustration--dark' : ''
                  }`}>
                  <img
                     className={`image ${imagePadding ? 'image--padding' : ''}`}
                     src={imagePath}
                     alt=""
                  />
               </div>
            </div>
            <div className="article__actions">
               <a
                  className="button button--documentation"
                  href={documentation}
                  download={helpFile}>
                  <span className="button__text">
                     <FormattedMessage id="documentation"></FormattedMessage>
                  </span>
               </a>
               <a
                  className="button button--download"
                  href={driver}
                  download={packageFile}>
                  <span className="button__text">
                     <FormattedMessage id="download"></FormattedMessage>
                  </span>
               </a>
               {price != 0 && (
                  <a className="button button--buy" onClick={openDialog}>
                     <span className="button__text">
                        <FormattedMessage id="buy"></FormattedMessage>
                     </span>{' '}
                     <span>{`${price} € HT`}</span>
                  </a>
               )}
            </div>
         </div>
         <BuyDialog
            open={dialogIsOpen}
            driverName={title}
            closeDialog={closeDialog}></BuyDialog>
      </>
   );
}

export default ShopItem;
