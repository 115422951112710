// firebase-config.js
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
   apiKey: 'AIzaSyApWjmOTMsMZatcrkvMukryVGT9_iUyRsk',
   authDomain: 'domiko-f6d00.firebaseapp.com',
   projectId: 'domiko-f6d00',
   storageBucket: 'domiko-f6d00.firebasestorage.app',
   messagingSenderId: '422482084844',
   appId: '1:422482084844:web:6283e860c6e94945a9b6c9',
   measurementId: 'G-PJ2WZNN6R9',
};

export const firebaseApp = initializeApp(firebaseConfig);
