import {
   getAuth,
   signInWithPopup,
   GoogleAuthProvider,
   getIdToken,
} from 'firebase/auth';
import { firebaseApp } from '../services/Firebase';
import { useEffect } from 'react';

const GoogleLoginPage = () => {
   const auth = getAuth(firebaseApp);
   const provider = new GoogleAuthProvider();

   useEffect(() => {
      // Get the authCode and redirect_uri from the URL query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const authCode = urlParams.get('authCode');
      const redirectUri = urlParams.get('redirect_uri');

      if (!authCode || !redirectUri) return;

      // Perform Google Sign-In
      const handleGoogleSignIn = async () => {
         try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            // Get Firebase ID token
            const firebaseIdToken = await getIdToken(user);

            // Send Firebase ID token to the backend
            const response = await fetch(redirectUri, {
               method: 'POST',
               headers: {
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify({
                  authCode,
                  firebaseIdToken,
               }),
            });

            const data = await response.json();
            console.log('OAuth 2.0 Tokens:', data);

            // Redirect the user to the final destination
            window.location.href = `${redirectUri}?success=true`;
         } catch (error) {
            console.error('Google Sign-In failed:', error);
         }
      };

      handleGoogleSignIn();
   }, []);

   return <div>Redirecting to Google Sign-In...</div>;
};

export default GoogleLoginPage;
