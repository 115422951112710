import IllustrationDuo from '../IllustrationDuo/IllustrationDuo';
import IllustrationSolo from '../IllustrationSolo/IllustrationSolo';
import { FormattedMessage } from 'react-intl';
import './Section.scss';

interface Props {
   title: string;
   text: string;
   image1: string;
   image2?: string;
   dark: boolean;
}

function Section({ title, text, image1, image2, dark }: Props) {
   return (
      <div className={`section ${dark ? 'section--dark' : ''}`}>
         <div className="section__content">
            <h2 className="section__title">
               <FormattedMessage id={title}></FormattedMessage>
            </h2>
            <p className="section__text">
               <FormattedMessage
                  id={text}
                  values={{
                     b: (chunks) => (
                        <strong style={{ fontWeight: 'bold' }}>{chunks}</strong>
                     ),
                  }}></FormattedMessage>
            </p>
         </div>
         <div className="section__illustration">
            {image2 !== undefined ? (
               <IllustrationDuo
                  image1={image1}
                  image2={image2}></IllustrationDuo>
            ) : (
               <IllustrationSolo image={image1}></IllustrationSolo>
            )}
         </div>
      </div>
   );
}

export default Section;
