import './TopBar.scss';
import logo from '../../assets/logo/sertek.svg';
import { NavLink } from 'react-router-dom';
import { Component } from 'react';
import { ReactComponent as Basket } from '../../assets/img/icons/add-to-cart.svg';
import { FormattedMessage } from 'react-intl';

interface TopBarState {
   menuOpen: boolean;
}

class TopBar extends Component<{}, TopBarState> {
   state: TopBarState = {
      menuOpen: false,
   };

   toggleMenu = () => {
      this.setState((prevState) => ({
         menuOpen: !prevState.menuOpen,
      }));
   };

   render() {
      const { menuOpen } = this.state;
      const imagePath = require('../../assets/img/icons/add-to-cart.svg');

      return (
         <div className="top-bar">
            <div className="elements">
               <div className="brand">
                  <img className="brand__logo" src={logo} alt="" />
                  <p className="brand__title">SERTEK</p>
               </div>
               <span className="burger-icon" onClick={this.toggleMenu}>
                  <span className="burger-icon__line"></span>
                  <span className="burger-icon__line"></span>
                  <span className="burger-icon__line"></span>
               </span>
               <div
                  className="menu"
                  style={{
                     transform: menuOpen ? 'translateX(0)' : 'translateX(100%)',
                  }}>
                  <NavLink
                     className="menu__list"
                     to="/"
                     onClick={this.toggleMenu}>
                     <FormattedMessage id="service"></FormattedMessage>
                  </NavLink>
                  <NavLink
                     className="menu__list"
                     to="/domiko"
                     onClick={this.toggleMenu}>
                     <FormattedMessage id="domiko"></FormattedMessage>
                  </NavLink>
                  {/* <NavLink
                     className="menu__list"
                     to="/prestation"
                     onClick={this.toggleMenu}>
                     <FormattedMessage id="service"></FormattedMessage>
                  </NavLink> */}
                  <NavLink
                     className="menu__list"
                     to="/store"
                     onClick={this.toggleMenu}>
                     <FormattedMessage id="store"></FormattedMessage>
                  </NavLink>
                  <NavLink
                     className="menu__list"
                     to="/contact"
                     onClick={this.toggleMenu}>
                     <FormattedMessage id="contact"></FormattedMessage>
                  </NavLink>
                  {/* <li className="menu__list" onClick={this.toggleMenu}>
                     <NavLink to="/basket">
                        <Basket fill="red" className="menu__icon"></Basket>
                     </NavLink>
                  </li> */}
               </div>
            </div>
         </div>
      );
   }
}

export default TopBar;
