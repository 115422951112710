import './PrivacyPolicyAlexaSkill.scss';

function PrivacyPolicyAlexaSkill() {
   return (
      <div className="container">
         <h1>Privacy Policy for Domiko Alexa Skill</h1>
         <p>
            <strong>Effective Date:</strong> 24 January 2025
         </p>

         <p>
            Sertek ("we," "our," or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use the Domiko Alexa Skill
            ("the Skill"). By enabling and using the Skill, you agree to the
            terms outlined in this Privacy Policy.
         </p>

         <h3>1. Information We Collect</h3>
         <p>
            When you use the Domiko Alexa Skill, we may collect the following
            types of information:
         </p>
         <ul>
            <li>
               Account Information: Information provided by your smart home
               account, the Amazon user ID, user name, and user email.
            </li>
            <li>
               Interaction Data: Voice commands, responses, and other data
               related to your use of the Skill, as processed by Amazon.
            </li>
            <li>
               Device Information: Information about your smart home devices,
               such as their status or functionality, as required to fulfill
               your commands.
            </li>
         </ul>

         <h3>2. How We Use Your Information</h3>
         <p>We use the information collected to:</p>
         <ul>
            <li>Provide and improve the functionality of the Skill.</li>
            <li>Respond to your commands and deliver requested services.</li>
            <li>Ensure compatibility with your smart home devices.</li>
         </ul>
         <p>
            We do not use your information for marketing purposes or share it
            with third parties, except as required to deliver the Skill’s
            functionality.
         </p>

         <h3>3. Information Sharing</h3>
         <p>
            We do not share your personal information with third parties except:
         </p>
         <ul>
            <li>
               With Amazon, as necessary to process your Alexa voice commands.
            </li>
            <li>To comply with legal obligations or protect our rights.</li>
         </ul>

         <h3>4. Data Retention</h3>
         <p>
            We retain your data only for as long as necessary to provide the
            Skill’s functionality or as required by law. Voice recordings and
            transcripts are managed by Amazon and are subject to Amazon's
            privacy policies.
         </p>

         <h3>5. Security</h3>
         <p>
            We implement appropriate technical and organizational measures to
            protect your information from unauthorized access, disclosure, or
            misuse. However, no system is completely secure, and we cannot
            guarantee absolute security.
         </p>

         <h3>6. Your Rights</h3>
         <p>You may:</p>
         <ul>
            <li>
               Disable the Skill at any time through the Alexa app, which will
               stop all data collection.
            </li>
            <li>Contact us to request access to or deletion of your data.</li>
         </ul>

         <h3>7. Changes to This Privacy Policy</h3>
         <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and the effective date will be updated
            accordingly.
         </p>

         <h3>8. Contact Us</h3>
         <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
         </p>

         <p>
            Email: <a href="mailto:support@sertek.fr">support@sertek.fr</a>
         </p>

         <hr />

         <h1>Politique de Confidentialité pour la Skill Alexa Domiko</h1>
         <p>
            <strong>Date d’entrée en vigueur :</strong> 24 January 2025
         </p>
         <p>
            Sertek ("nous," "notre," ou "nos") s'engage à protéger votre vie
            privée. Cette Politique de Confidentialité explique comment nous
            collectons, utilisons, divulguons et protégeons vos informations
            lorsque vous utilisez la Domiko Alexa Skill ("le Skill"). En
            activant et en utilisant le Skill, vous acceptez les termes énoncés
            dans cette Politique de Confidentialité.
         </p>

         <h3>1. Informations que nous collectons</h3>
         <p>
            Lorsque vous utilisez la Domiko Alexa Skill, nous pouvons collecter
            les types d'informations suivants :
         </p>
         <ul>
            <li>
               Informations de compte : Informations fournies par votre compte
               de maison intelligente, comme l'ID utilisateur Amazon, le nom
               d'utilisateur et l'adresse email.
            </li>
            <li>
               Données d'interaction : Commandes vocales, réponses et autres
               données liées à votre utilisation du Skill, telles que traitées
               par Amazon.
            </li>
            <li>
               Informations sur les appareils : Informations sur vos appareils
               de maison intelligente, telles que leur état ou leur
               fonctionnalité, nécessaires pour exécuter vos commandes.
            </li>
         </ul>

         <h3>2. Comment nous utilisons vos informations</h3>
         <p>Nous utilisons les informations collectées pour :</p>
         <ul>
            <li>Fournir et améliorer les fonctionnalités du Skill.</li>
            <li>Répondre à vos commandes et fournir les services demandés.</li>
            <li>
               Assurer la compatibilité avec vos appareils de maison
               intelligente.
            </li>
         </ul>
         <p>
            Nous n'utilisons pas vos informations à des fins de marketing ni ne
            les partageons avec des tiers, sauf si nécessaire pour fournir les
            fonctionnalités du Skill.
         </p>

         <h3>3. Partage d'informations</h3>
         <p>
            Nous ne partageons vos informations personnelles avec des tiers que
            :
         </p>
         <ul>
            <li>
               Avec Amazon, si nécessaire pour traiter vos commandes vocales
               Alexa.
            </li>
            <li>
               Pour respecter des obligations légales ou protéger nos droits.
            </li>
         </ul>

         <h3>4. Conservation des données</h3>
         <p>
            Nous conservons vos données uniquement aussi longtemps que
            nécessaire pour fournir les fonctionnalités du Skill ou conformément
            à la loi. Les enregistrements vocaux et les transcriptions sont
            gérés par Amazon et sont soumis à leurs politiques de
            confidentialité.
         </p>

         <h3>5. Sécurité</h3>
         <p>
            Nous mettons en œuvre des mesures techniques et organisationnelles
            appropriées pour protéger vos informations contre l'accès non
            autorisé, la divulgation ou l'utilisation abusive. Cependant, aucun
            système n'est totalement sécurisé et nous ne pouvons garantir une
            sécurité absolue.
         </p>

         <h3>6. Vos droits</h3>
         <p>Vous pouvez :</p>
         <ul>
            <li>
               Désactiver le Skill à tout moment via l'application Alexa, ce qui
               arrêtera toute collecte de données.
            </li>
            <li>
               Nous contacter pour demander l'accès à vos données ou leur
               suppression.
            </li>
         </ul>

         <h3>7. Modifications de cette Politique de Confidentialité</h3>
         <p>
            Nous pouvons mettre à jour cette Politique de Confidentialité de
            temps à autre. Toute modification sera publiée sur cette page et la
            date d'entrée en vigueur sera mise à jour en conséquence.
         </p>

         <h3>8. Contactez-nous</h3>
         <p>
            Si vous avez des questions ou des préoccupations concernant cette
            Politique de Confidentialité, veuillez nous contacter à :
         </p>
         <p>
            Email: <a href="mailto:support@sertek.fr">support@sertek.fr</a>
         </p>
      </div>
   );
}

export default PrivacyPolicyAlexaSkill;
