import './TermsOfUseAlexaSkill.scss';

function TermsOfUseAlexaSkill() {
   return (
      <div className="container">
         <h1>Terms of Use for Domiko Alexa Skill</h1>
         <p>
            <strong>Effective Date:</strong> 24 January 2025
         </p>
         <p>
            These Terms of Use ("Terms") govern your use of the Domiko Alexa
            Skill ("the Skill"). By enabling and using the Skill, you agree to
            these Terms. If you do not agree, do not enable or use the Skill.
         </p>

         <h2>1. Use of the Skill</h2>
         <p>
            The Skill is designed to enable voice-controlled access to your
            Domiko smart home system. By using the Skill, you acknowledge and
            agree that:
         </p>
         <ul>
            <li>
               The Skill is provided "as is," and we make no guarantees
               regarding its performance or availability.
            </li>
            <li>
               You are responsible for ensuring your devices are configured
               correctly and used in a secure manner.
            </li>
         </ul>

         <h2>2. User Responsibilities</h2>
         <p>You agree to:</p>
         <ul>
            <li>
               Use the Skill in compliance with all applicable laws and
               regulations.
            </li>
            <li>
               Not use the Skill for any unlawful or unauthorized purposes.
            </li>
            <li>
               Ensure that only authorized individuals have access to the Skill
               and your connected devices.
            </li>
         </ul>

         <h2>3. Limitations of Liability</h2>
         <p>
            To the fullest extent permitted by law, we are not liable for any
            damages arising from your use of the Skill, including but not
            limited to:
         </p>
         <ul>
            <li>Loss of data.</li>
            <li>Unauthorized access to your devices.</li>
            <li>Any interruptions or errors in the Skill’s operation.</li>
         </ul>

         <h2>4. Intellectual Property</h2>
         <p>
            All content, trademarks, and intellectual property related to the
            Skill are owned by Domiko or its licensors. You may not reproduce,
            modify, or distribute any part of the Skill without prior written
            consent.
         </p>

         <h2>5. Termination</h2>
         <p>
            We reserve the right to suspend or terminate your access to the
            Skill at any time for any reason, including violation of these
            Terms.
         </p>

         <h2>6. Changes to These Terms</h2>
         <p>
            We may update these Terms from time to time. Any changes will be
            posted on this page, and your continued use of the Skill constitutes
            acceptance of the updated Terms.
         </p>

         <h2>7. Governing Law</h2>
         <p>
            These Terms are governed by the laws of France without regard to its
            conflict of laws principles.
         </p>

         <h2>8. Contact Us</h2>
         <p>
            If you have any questions about these Terms, please contact us at:
         </p>
         <p>
            Email: <a href="mailto:support@sertek.fr">support@sertek.fr</a>
         </p>

         <hr />

         <h1>Conditions d’utilisation pour le Skill Alexa Domiko</h1>
         <p>
            <strong>Date d’entrée en vigueur :</strong> 24 janvier 2025
         </p>
         <p>
            Ces conditions d’utilisation (\"Conditions\") régissent votre
            utilisation du Skill Alexa Domiko (\"le Skill\"). En activant et en
            utilisant le Skill, vous acceptez ces Conditions. Si vous n’acceptez
            pas, n’activez pas ou n’utilisez pas le Skill.
         </p>

         <h2>1. Utilisation du Skill</h2>
         <p>
            Le Skill est conçu pour permettre un accès contrôlé par la voix à
            votre système domotique Domiko. En utilisant le Skill, vous
            reconnaissez et acceptez que :
         </p>
         <ul>
            <li>
               Le Skill est fourni \"tel quel\", et nous ne garantissons pas sa
               performance ou sa disponibilité.
            </li>
            <li>
               Vous êtes responsable de vous assurer que vos appareils sont
               configurés correctement et utilisés de manière sécurisée.
            </li>
         </ul>

         <h2>2. Responsabilités de l’utilisateur</h2>
         <p>Vous acceptez de :</p>
         <ul>
            <li>
               Utiliser le Skill en conformité avec toutes les lois et
               réglementations applicables.
            </li>
            <li>
               Ne pas utiliser le Skill à des fins illégales ou non autorisées.
            </li>
            <li>
               Vous assurer que seules des personnes autorisées ont accès au
               Skill et à vos appareils connectés.
            </li>
         </ul>

         <h2>3. Limitations de responsabilité</h2>
         <p>
            Dans toute la mesure permise par la loi, nous ne sommes pas
            responsables des dommages résultant de votre utilisation du Skill, y
            compris, mais sans s’y limiter :
         </p>
         <ul>
            <li>Perte de données.</li>
            <li>Accès non autorisé à vos appareils.</li>
            <li>
               Toute interruption ou erreur dans le fonctionnement du Skill.
            </li>
         </ul>

         <h2>4. Propriété intellectuelle</h2>
         <p>
            Tous les contenus, marques et droits de propriété intellectuelle
            liés au Skill sont la propriété de Domiko ou de ses concédants. Vous
            ne pouvez pas reproduire, modifier ou distribuer une partie du Skill
            sans consentement écrit préalable.
         </p>

         <h2>5. Résiliation</h2>
         <p>
            Nous nous réservons le droit de suspendre ou de résilier votre accès
            au Skill à tout moment pour quelque raison que ce soit, y compris en
            cas de violation de ces Conditions.
         </p>

         <h2>6. Modifications de ces Conditions</h2>
         <p>
            Nous pouvons mettre à jour ces Conditions de temps à autre. Tout
            changement sera publié sur cette page, et votre utilisation continue
            du Skill constitue votre acceptation des Conditions mises à jour.
         </p>

         <h2>7. Loi applicable</h2>
         <p>
            Ces Conditions sont régies par les lois de la France, sans tenir
            compte des principes de conflits de lois.
         </p>

         <h2>8. Nous contacter</h2>
         <p>
            Si vous avez des questions sur ces Conditions, veuillez nous
            contacter à l’adresse suivante :
         </p>
         <p>
            Email : <a href="mailto:support@sertek.fr">support@sertek.fr</a>
         </p>
      </div>
   );
}

export default TermsOfUseAlexaSkill;
