import Header from '../components/Header/Header';
import Section from '../components/Section/Section';

function IntegrationPage() {
   return (
      <>
         <Header />
         <Section
            title="prestaIntroductionTitle"
            text="prestaIntroductionText"
            image1="presta/prestaIntroduction"
            dark={false}
         />
         <Section
            title="prestaServiceTitle"
            text="prestaServiceText"
            image1="presta/prestaService"
            dark={true}
         />
         <Section
            title="prestaCrestronTitle"
            text="prestaCrestronText"
            image1="presta/prestaCrestron"
            dark={false}
         />
         <Section
            title="prestaProgrammingTitle"
            text="prestaProgrammingText"
            image1="presta/prestaProgramming"
            dark={true}
         />
      </>
   );
}

export default IntegrationPage;
